<template>
    <div>
        <v-card 
            v-if="fallback" 
            class="grey lighten-3 d-flex justify-center align-center"
            width="100"
            height="40"
            flat
        >
            <v-icon size="20" color="primary">mdi-panorama</v-icon>
        </v-card>
        <v-img 
            v-else
            class="grey lighten-3"
            :src="src"
            :width="width" 
            :height="height"
            :aspect-ratio="aspectRatio" 
            :contain="false"
            max-height="80"
        >
            <template v-slot:placeholder>
                <v-row class="fill-height" align="center" justify="center" no-gutters>
                    <v-progress-circular color="primary" size="30" width="4" indeterminate />
                </v-row>
            </template>
        </v-img>
    </div>
</template>

<script>
export default {
    name: "Thumbnail",
    props: {
        thumbnailUrl: {
            type: String,
            required: true
        }
    },
    data: () => ({
        src: '',
        aspectRatio: 1.777,
        fallback: false
    }),
    computed: {
        width () {
            return this.aspectRatio < 1 ? 80 * this.aspectRatio : 100
        },
        height () {
            return this.aspectRatio < 1 ? 80 : 100 / this.aspectRatio
        },
    },
    methods: {
        loadImage (url) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.onload = () => resolve(img)
                img.onerror = () => reject()
                img.src = url
            })
        },
    },
    created () {
        this.loadImage(this.thumbnailUrl)
        .then(res => {
            this.aspectRatio = res.naturalWidth / res.naturalHeight
            this.src = res.src
        })
        .catch(() => {
            this.fallback = true
        })    
    }
}
</script>
